<template>
 <div>
  <svg height="183" viewBox="0 0 183 183" width="183" xmlns="http://www.w3.org/2000/svg">
   <g id="icon_logo_polarsteps" transform="translate(-151 -2561)">
    <rect :fill="background" data-name="Rectangle 187" height="183" id="Rectangle_187" transform="translate(151 2561)" width="183"/>
    <g data-name="Group 380" id="Group_380" transform="translate(-472.861 1818.248)">
     <path d="M1242.391,863.129c0,2.415-2.1,3.578-4.495,3.578a4.809,4.809,0,0,1-4.7-2.661l2.5-1.409a2.13,2.13,0,0,0,2.191,1.454c.917,0,1.543-.313,1.543-.962,0-1.632-5.769-.738-5.769-4.674,0-2.281,1.945-3.556,4.249-3.556a4.71,4.71,0,0,1,4.249,2.37l-2.46,1.342a1.891,1.891,0,0,0-1.789-1.141c-.716,0-1.3.313-1.3.917C1236.621,860.043,1242.391,859.015,1242.391,863.129Z" data-name="Path 381" fill="#022c3a" fill-rule="evenodd" id="Path_381" transform="translate(-456.669 -26.086)"/>
     <path d="M1180.507,854.9a4.344,4.344,0,0,0-3.623,1.632v-1.319H1174v15.653h2.885v-5.792a4.344,4.344,0,0,0,3.623,1.632,5.922,5.922,0,0,0,0-11.807Zm-.537,9.057a3.153,3.153,0,1,1,3.108-3.153A3,3,0,0,1,1179.97,863.956Z" data-name="Path 382" fill="#022c3a" fill-rule="evenodd" id="Path_382" transform="translate(-410.707 -26.086)"/>
     <path d="M1124.861,860.8a5.782,5.782,0,1,0-11.561,0,5.755,5.755,0,0,0,6.127,5.9,5.657,5.657,0,0,0,4.875-2.393l-2.326-1.342a3.1,3.1,0,0,1-2.5,1.118,2.936,2.936,0,0,1-3.153-2.1h8.43A6.67,6.67,0,0,0,1124.861,860.8Zm-8.587-1.051a2.788,2.788,0,0,1,2.929-2.258,2.713,2.713,0,0,1,2.773,2.258Z" data-name="Path 383" fill="#022c3a" fill-rule="evenodd" id="Path_383" transform="translate(-363.581 -26.086)"/>
     <path d="M1077.93,848.2v4.651c0,1.208.872,1.23,2.527,1.14v2.616c-4.047.447-5.411-.738-5.411-3.757V848.2H1073.1v-2.773h1.945v-2.258l2.885-.872v3.131h2.527V848.2Z" data-name="Path 384" fill="#022c3a" fill-rule="evenodd" id="Path_384" transform="translate(-332.37 -16.304)"/>
     <path d="M1036.691,863.129c0,2.415-2.1,3.578-4.495,3.578a4.809,4.809,0,0,1-4.7-2.661l2.5-1.409a2.13,2.13,0,0,0,2.191,1.454c.917,0,1.543-.313,1.543-.962,0-1.632-5.769-.738-5.769-4.674,0-2.281,1.946-3.556,4.249-3.556a4.709,4.709,0,0,1,4.249,2.37l-2.46,1.342a1.891,1.891,0,0,0-1.789-1.141c-.716,0-1.3.313-1.3.917C1030.921,860.043,1036.691,859.015,1036.691,863.129Z" data-name="Path 385" fill="#022c3a" fill-rule="evenodd" id="Path_385" transform="translate(-296.967 -26.086)"/>
     <path d="M1001.584,855.3v3.22c-1.5-.179-3.4.492-3.4,2.84V866.7H995.3V855.524h2.885v1.923A3.441,3.441,0,0,1,1001.584,855.3Z" data-name="Path 386" fill="#022c3a" fill-rule="evenodd" id="Path_386" transform="translate(-271.967 -26.397)"/>
     <path d="M939.179,855.213v1.319a4.451,4.451,0,0,0-3.645-1.632,5.924,5.924,0,0,0,0,11.807,4.451,4.451,0,0,0,3.645-1.632v1.319h2.885V855.213Zm-3.108,8.743a3.153,3.153,0,1,1,3.108-3.153A3,3,0,0,1,936.07,863.956Z" data-name="Path 387" fill="#022c3a" fill-rule="evenodd" id="Path_387" transform="translate(-221.347 -26.086)"/>
     <rect data-name="Rectangle 190" height="16.324" id="Rectangle_190" transform="translate(704.258 823.983)" width="2.885"/>
     <path d="M854.9,854.9a5.9,5.9,0,1,0,5.926,5.9A5.842,5.842,0,0,0,854.9,854.9Zm0,8.989a3.086,3.086,0,1,1,3.041-3.086A2.968,2.968,0,0,1,854.9,863.889Z" data-name="Path 388" fill="#022c3a" fill-rule="evenodd" id="Path_388" transform="translate(-158.382 -26.086)"/>
     <path d="M795.507,854.9a4.344,4.344,0,0,0-3.623,1.632v-1.319H789v15.653h2.885v-5.792a4.344,4.344,0,0,0,3.623,1.632,5.922,5.922,0,0,0,0-11.807Zm-.537,9.057a3.153,3.153,0,1,1,3.108-3.153A3,3,0,0,1,794.971,863.956Z" data-name="Path 389" fill="#022c3a" fill-rule="evenodd" id="Path_389" transform="translate(-111.799 -26.086)"/>
     <path d="M658.417,821.3a13.417,13.417,0,1,0,13.417,13.417A13.418,13.418,0,0,0,658.417,821.3Zm0,24.151a10.734,10.734,0,1,1,10.734-10.734A10.734,10.734,0,0,1,658.417,845.451Z" data-name="Path 390" fill="#022c3a" fill-rule="evenodd" id="Path_390" transform="translate(0 0)"/>
     <path d="M699.186,879.328v7.516a.447.447,0,0,1-.767.322l-5.36-5.268a.483.483,0,0,1-.092-.139.447.447,0,0,1,.239-.586l1.375-.577a1.567,1.567,0,0,0,2.61-1.094l1.375-.577a.455.455,0,0,1,.163-.036A.449.449,0,0,1,699.186,879.328Z" data-name="Path 391" fill="#022c3a" fill-rule="evenodd" id="Path_391" transform="translate(-37.214 -44.712)"/>
     <path d="M695.049,852.237l-1.378.577a1.565,1.565,0,0,0-2.607,1.093l-1.375.577a.456.456,0,0,1-.163.036.446.446,0,0,1-.456-.438v-7.516a.434.434,0,0,1,.134-.329.448.448,0,0,1,.633.007l5.358,5.268a.444.444,0,0,1-.145.725Z" data-name="Path 392" fill="#de2a52" fill-rule="evenodd" id="Path_392" transform="translate(-34.214 -19.262)"/>
    </g>
   </g>
  </svg>
 </div>
</template>
<script>
export default {
  props: ["color1", "color2", "background"],
};
</script>